import {
  reactive, watch, set, nextTick
} from 'vue';
import technicalReasons from '@/api/nsi/technicalReasons';
import EventCardCorrelationRepository from '@/repositories/EventCardCorrelationRepository';
import { helpers } from '@vuelidate/validators';
import cloneDeep from 'lodash.clonedeep';

const getEmptyState = () => ({

  dialogOpen: false,
  currentCorrelationId: null,

  showSourceVariant: false,
  sourceVariants: [],
  selectedSourceVariantId: null,

  showDestVariant: false,
  destVariants: [],
  selectedDestVariantId: null,

  techReasons: [],

  statusId: 0,
  statusName: '',
  currentStatusId: 0,
  statusEventId: 0,
  statusEventName: '',
  currentStatusEventId: 0,


  totalMappingCount: 0,
  mappingCount: 0,

  destEventCard: null, /* Куда */
  sourceEventCard: null, /* Откуда */
  eventCompare: {},
  sepCompare: new Map(),
  seCompare: new Map(),
  selectedSourceSep: 0,
  selectedDestSep: 0,
  selectedSourceSe: 0,
  selectedDestSe: 0,


  selectedSepId: 0,
  selectedSeId: 0,

  labelSpan: 8,
  dataSpan: 16,
  selectedDestSepCard: null,
  selectedSourceSepCard: null,
  selectedDestSeCard: null,
  selectedSourceSeCard: null,

  hideRepeat: false,
  estimations: [],
  allowStaffCategories: [],
  filteredEstimations: []
});


class EventMappingModalStore {
  GUID_EMPTY = '00000000-0000-0000-0000-000000000000';

  constructor(isExternal, dateHelpers) {
    this.isExternal = isExternal;
    this.dateHelpers = dateHelpers;
    watch(
      () => this.state.destEventCard,
      () => {
        this.reload();
      },
      { deep: true }
    );
  }

  state = reactive(getEmptyState());

  setEstimations(estimations) {
    this.state.estimations = estimations;
    this.state.filteredEstimations = estimations.filter((x) => x.code === 'НЛ'
      || x.code === 'НО'
      || x.code === 'НИ'
      || x.code === 'НИ сигнал'
      || x.code === 'НЛ сигнал'
      || x.code === 'НО сигнал');
  }

  setStaffCategories(staffCategories) {
    debugger;
    this.state.allowStaffCategories = staffCategories.filter((x) => x.code === '00');
  }

  corStatuses = [
    {
      id: 1,
      name: 'Рассмотрение СО ЕЭС',
    },
    {
      id: 2,
      name: 'Рассмотрение Субъектом',
    },
    {
      id: 3,
      name: 'Сопоставлено - Конфликт',
    },
    {

      id: 4,
      name: 'Сопоставлено - согласие ',
    },
    {
      id: 0,
      name: 'Не определено',
    }
  ];

  intSepStatuses = [
    {
      id: 0,
      name: '[НЕ ОПРЕДЕЛЕНО]',
      prev: new Set()
    },
    {
      id: 2,
      name: 'Рассмотрение Субъектом - Корректировка',
      prev: new Set([0, 2])
    },
    {
      id: 1,
      name: 'Рассмотрение СО ЕЭС - Корректировка',
      prev: new Set([1])
    },
    {

      id: 4,
      name: 'Рассмотрение Субъектом - Корректировка повторная',
      prev: new Set([1, 4])
    },
    {
      id: 3,
      name: 'Рассмотрение СО ЕЭС - Корректировка повторная',
      prev: new Set([3])
    },
    {
      id: 6,
      name: 'Сопоставлено - Конфликт',
      prev: new Set([3, 6])
    },
    {
      id: 5,
      name: 'Сопоставлено - согласие',
      prev: new Set([0, 1, 3, 5, 6])
    },
  ];

  extSepStatuses = [
    {
      id: 0,
      name: '[НЕ ОПРЕДЕЛЕНО]',
      prev: new Set()
    },
    {
      id: 1,
      name: 'Рассмотрение СО ЕЭС - Корректировка',
      prev: new Set([0, 1])
    },
    {
      id: 2,
      name: 'Рассмотрение Субъектом - Корректировка',
      prev: new Set([2])
    },
    {

      id: 3,
      name: 'Рассмотрение СО ЕЭС - Корректировка повторная',
      prev: new Set([2, 3])
    },
    {
      id: 4,
      name: 'Рассмотрение Субъектом - Корректировка повторная',
      prev: new Set([4])
    },
    {
      id: 6,
      name: 'Сопоставлено - Конфликт',
      prev: new Set([4, 6])
    },
    {
      id: 5,
      name: 'Сопоставлено - согласие',
      prev: new Set([0, 2, 4, 5, 6])
    },
  ];

  isValidStatus(currentStatusId) {
    if ((this.state.statusId === 1 && (currentStatusId === 1 || currentStatusId === 3))
      || (this.state.statusId === 2 && (currentStatusId === 2 || currentStatusId === 4))
      || currentStatusId === 5 || currentStatusId === 6
      || (currentStatusId === 0 && this.state.statusId === 0)) {
      return true;
    }
    return false;  
  }

  getAllowedCorSepStatuses(id) {
    if (this.isExternal) {
      return this.extSepStatuses.filter((el) => el.prev.has(id));
    }
    return this.intSepStatuses.filter((el) => el.prev.has(id));
  }

  getExtSepStatuses(id) {
    const st = this.extSepStatuses.find((el) => el.id === id);
    return st || {};
  }

  getIntSepStatuses(id) {
    const st = this.intSepStatuses.find((el) => el.id === id);
    return st || {};
  }

  getInitCorSepStatus() {
    if (this.isExternal) {
      return this.getExtSepStatuses(0);
    }
    return this.getIntSepStatuses(0);
  }

  async loadTechReasonsAsync() {
    if (this.state.techReasons.length > 0) return;
    const techReasons = await technicalReasons.getTechnicalReasonsWithPagination(1, 1000000, null, 'name', false);
    if (techReasons.data) {
      this.state.techReasons = this.prepareTechReasons(techReasons.data.items);
    }
  }

  // eslint-disable-next-line class-methods-use-this
  prepareTechReasons(data) {
    const items = [];
    data.forEach((el) => {
      if (!el.children || el.children.length === 0) {
        items.push(el);
      }
      el.children.forEach((el2) => {
        items.push(el2);
      });
    });
    return items.filter((el) => el.actual)
      .sort((a, b) => (a.code > b.code ? 1 : -1));
  }

  /**
   * Загрузка по ид сопоставления с внутреннего сайта
   * @param id
   * @returns {Promise<void>}
   */
  async openByIdAsync(id) {
    await this.loadByIdAsync(id);

    if (!this.state.sourceEventCard && this.state.destEventCard) {
      this.state.showSourceVariant = true;
      this.state.sourceVariants = await EventCardCorrelationRepository.loadMappingEventAsync(this.state.destEventCard.primaryEquipmentId, this.state.destEventCard.eventTime);
    }
    if (this.state.sourceEventCard) {
      this.state.showSourceVariant = false;
    }

    if (this.state.sourceEventCard && !this.state.destEventCard) {
      this.state.showDestVariant = true;
      this.state.destVariants = await EventCardCorrelationRepository.getAllowedEventCards(this.state.sourceEventCard.eventTime, this.state.sourceEventCard.primaryEquipmentId, this.state.sourceEventCard.secondEquipmentPanelId);
    }
    if (this.state.destEventCard) {
      this.state.showDestVariant = false;
    }

    this.reCompareCurrent();
    nextTick(() => {
      this.selected(this.state.destEventCard);
    });

    this.state.dialogOpen = true;
  }

  async loadByIdAsync(id) {
    const res = await EventCardCorrelationRepository.getAsync(id);

    this.state.currentCorrelationId = id;

    if (res.destinationEventCard) {
      res.destinationEventCard.eventTime = this.dateHelpers.toUserDate(res.destinationEventCard.eventTime);
    }
    if (res.sourceEventCard) {
      res.sourceEventCard.eventTime = this.dateHelpers.toUserDate(res.sourceEventCard.eventTime);
    }


    this.state.sourceEventCard = res.sourceEventCard;
    this.state.destEventCard = res.destinationEventCard;

    this.prepareSeps(res.sourceEventCard);
    this.prepareSeps(res.destinationEventCard);

    this.state.statusId = null;
    this.state.statusName = null;
    this.state.statusEventId = null;
    this.state.statusEventName = null;

    this.state.currentStatusId = res.statusId;
    this.state.currentStatusName = res.statusName;
    this.state.statusEventId = res.statusEventId;
    this.state.currentStatusEventId = res.statusEventId;
    // this.state.statusEventName =  this.extSepStatuses.find((el) => el.id === res.statusEventId).name || this.intSepStatuses.find((el) => el.id === res.statusEventId).name; // res.statusEventName;


    // this.state.currentStatusId = res.destinationEventCard?.statusId;
  }

  /**
   * Открыть с внешнего сайта со страницы создания события
   * @param primaryEquipmentId
   * @param eventTime
   * @param secondEquipmentPanelId
   */
  // eslint-disable-next-line class-methods-use-this
  async openExtByEventIdAsync(primaryEquipmentId, eventTime, secondEquipmentPanelId) {
    if ((!primaryEquipmentId && !secondEquipmentPanelId) || !eventTime) return [];
    const res = await EventCardCorrelationRepository.getAllowedEventCards(eventTime, primaryEquipmentId, secondEquipmentPanelId);
    this.state.sourceVariants = res.map((el) => ({
      id: el.id,
      number: el.number
    }));
    this.state.showSourceVariant = true;
    return this.state.sourceVariants;
  }

  /**
   * Открыть с внутреннего сайта со страницы создания аварии
   * @param primaryEquipmentIds
   * @param incidentTime
   * @param secondEquipmentPanelIds
   */
  async openIntByIncidentAsync(primaryEquipmentIds, incidentTime, secondEquipmentPanelIds) {
    if (!primaryEquipmentIds || !incidentTime) return [];
    this.state.sourceEventCard = null;
    this.state.destEventCard = null;
    const res = await EventCardCorrelationRepository.getAllowedExtEventCards(incidentTime, primaryEquipmentIds, secondEquipmentPanelIds);
    // this.state.showSourceVariant = true;
    this.state.sourceVariants = res.map((el) => ({
      id: el.id,
      number: el.number
    }));
    this.state.showSourceVariant = true;
    return this.state.sourceVariants;
  }

  /**
   * Создание сопоставления по ид карточки события
   * @param eventCardId
   * @returns {Promise<void>}
   */
  // eslint-disable-next-line class-methods-use-this
  async createByEventCardId(eventCardId) {
    if (this.isExternal) {
      /**
       *  Загрузили внутреннее для внешнего
       */
      const internal = await EventCardCorrelationRepository.loadEventCardByIdAsync(eventCardId);


      this.prepareSeps(internal);
      this.state.sourceEventCard = internal;

      /**
       * Загрузить список на сопоставление внешних
       */
      const primaryEquipmentIds = [];
      const secondEquipmentPanelIds = [];
      if (this.state.sourceEventCard.primaryEquipmentId) {
        primaryEquipmentIds.push(this.state.sourceEventCard.primaryEquipmentId);
      }
      if (this.state.sourceEventCard.secondEquipmentPanelId) {
        secondEquipmentPanelIds.push(this.state.sourceEventCard.secondEquipmentPanelId);
      }
      this.state.destVariants = await EventCardCorrelationRepository.getAllowedExtEventCards(this.state.sourceEventCard.eventTime,
        primaryEquipmentIds,
        secondEquipmentPanelIds);


      this.prepareSourceEventCard(this.state.sourceEventCard);
      this.state.showDestVariant = true;

      this.state.dialogOpen = true;
    } else {
      /**
       *  Загрузили внешнее для внутреннего
       */
      const ext = await EventCardCorrelationRepository.getExtEventCardByIdAsync(eventCardId);

      this.prepareSeps(ext);
      this.state.sourceEventCard = ext;

      /**
       * Загрузить список на сопоставление внутренних
       */
      this.state.destVariants = await EventCardCorrelationRepository.getAllowedEventCards(this.state.sourceEventCard.eventTime,
        this.state.sourceEventCard.primaryEquipmentId,
        this.state.sourceEventCard.secondEquipmentPanelId);

      this.prepareSourceEventCard(this.state.sourceEventCard);
      this.state.showDestVariant = true;
      this.state.dialogOpen = true;
    }
  }

  // eslint-disable-next-line class-methods-use-this
  prepareSourceEventCard(eventCard) {
    if (!eventCard) return;
    eventCard.eventTime = this.dateHelpers.toUserDate(eventCard.eventTime);
  }

  /**
   * Загрузка источника по ид
   * @param id
   * @returns {Promise<void>}
   */
  async loadSourceVariantAsync(id) {
    debugger;
    if (this.isExternal) {
      this.state.sourceEventCard = await EventCardCorrelationRepository.loadEventCardByIdAsync(id);
    } else {
      this.state.sourceEventCard = await EventCardCorrelationRepository.getExtEventCardByIdAsync(id);
      // await this.loadByIdAsync(id);
    }
    this.prepareSourceEventCard(this.state.sourceEventCard);
    this.reCompareCurrent();
  }

  /**
   * Загрузка приемника по ид
   * @param id
   * @returns {Promise<void>}
   */
  async loadDestVariantAsync(id) {
    if (this.isExternal) {
      this.state.destEventCard = await EventCardCorrelationRepository.getExtEventCardByIdAsync(id);
      this.prepareDestEventCard(this.state.destEventCard);
    } else {
      this.state.destEventCard = await EventCardCorrelationRepository.loadEventCardByIdAsync(id);
      this.prepareDestEventCard(this.state.destEventCard);
    }
    this.reCompareCurrent();
    this.selected(this.state.destEventCard);

    // eslint-disable-next-line no-unused-vars
    // const err = this.validateEvent(this.state.destEventCard);
    // debugger;
  }

  //
  // eslint-disable-next-line class-methods-use-this
  prepareDestEventCard(eventCard) {
    if (!eventCard) return;
    eventCard.eventTime = this.dateHelpers.toUserDate(eventCard.eventTime);
    eventCard.secondEquipmentPanelCards.forEach((el) => {
      el.sourceSepCardId = el.id;
     // el.id = '00000000-0000-0000-0000-000000000000';
      // const initSt = this.getInitCorSepStatus();
      // el.statusName = initSt.name;
      // el.statusId = null;
      el.currentStatusId = el.statusId; 
      el.getStatusId = function () {
        return this.statusId ? this.statusId : this.currentStatusId;
      };
      // this.prepareOrganizationalReasons(el);
      // if (el.secondEquipmentCards) {
      //   el.secondEquipmentCards.forEach((el2) => {
      //     el2.id = '00000000-0000-0000-0000-000000000000';
      //     //  this.prepareOrganizationalReasons(el2);
      //   });
      // }
    });
  }

  prepareOrganizationalReasons(obj) {
    if (obj.organizationalReasons && obj.organizationalReasons.length) {
      obj.organizationalReasons.forEach((el) => {
        el.id = this.GUID_EMPTY;
      });
    }
  }

  // eslint-disable-next-line class-methods-use-this
  prepareSeps(sep) {
    if (sep && sep.secondEquipmentPanelCards) {
      // eslint-disable-next-line no-return-assign
      sep.secondEquipmentPanelCards.forEach((el) => {
        el.currentStatusId = el.statusId;
        // if (el.statusId !== 5 && el.statusId !== 6) {
        //   el.statusId = null;
        // }
        // el.statusId = null;
        el.getStatusId = function () {
          return this.statusId ? this.statusId : this.currentStatusId;
        };
        // el.getStatusName = function () {
        //   return this.statusId ? this.statusName : el.currentStatusName;
        // };
      });
    }
  }

  reload() {
    this.reCompareCurrent();
    this.reselectAll();
    this.compareSecondEquipmentPanelCards();
  }

  reCompareCurrent() {
    this.compare(this.state.sourceEventCard, this.state.destEventCard);
  }

  compare(sEvent, dEvent) {
    if (!sEvent || !dEvent) return;

    this.state.sepCompare = new Map();
    this.state.seCompare = new Map();

    this.state.eventCompare = this.compareEvent(sEvent, dEvent);

    this.compareSeps(sEvent, dEvent);
  }

  // eslint-disable-next-line class-methods-use-this
  compareEvent(s, d) {
    const compE = {};
    compE.number = (s.number != null || d.number != null) && s.number !== d.number;
    compE.eventTime = (s.eventTime != null || d.eventTime != null) && s.eventTime !== d.eventTime;
    compE.secondEquipmentPanelName = (s.secondEquipmentPanelName != null || d.secondEquipmentPanelName != null) && s.secondEquipmentPanelName !== d.secondEquipmentPanelName;
    compE.primaryEquipmentName = (s.primaryEquipmentName != null || d.primaryEquipmentName != null) && s.primaryEquipmentName !== d.primaryEquipmentName;
    compE.shortCircuitFormId = (s.shortCircuitFormId != null || d.shortCircuitFormId != null) && s.shortCircuitFormId !== d.shortCircuitFormId;
    compE.shortCircuitPlaceId = (s.shortCircuitPlaceId != null || d.shortCircuitPlaceId != null) && s.shortCircuitPlaceId !== d.shortCircuitPlaceId;
    compE.faultedPhases = !this.compareArray(s.faultedPhases, d.faultedPhases);
    compE.cardInformation = !this.compareArray(s.cardInformation, d.cardInformation);
    compE.description = s.description !== d.description;
    compE.eventTypeId = (s.eventTypeId != null || d.eventTypeId != null) && s.eventTypeId !== d.eventTypeId;

    return compE;
  }

  compareSeps(sEvent, dEvent) {
    if (sEvent.secondEquipmentPanelCards.length > 0 && dEvent.secondEquipmentPanelCards.length) {
      for (let i = 0; i < sEvent.secondEquipmentPanelCards.length; i++) {
        const dSep = dEvent.secondEquipmentPanelCards.find((el) => el.secondEquipmentPanelId === sEvent.secondEquipmentPanelCards[i].secondEquipmentPanelId);
        if (dSep) {
          this.state.sepCompare.set(dSep.secondEquipmentPanelId, this.compareSep(sEvent.secondEquipmentPanelCards[i], dSep));
          this.compareSes(dSep.secondEquipmentPanelId, sEvent.secondEquipmentPanelCards[i], dSep);
        }
      }
    }
  }

  compareSep(s, d) {
    const compSep = {};
    compSep.estimationId = (s.estimationId != null || d.estimationId != null) && s.estimationId !== d.estimationId;
    compSep.energyObjectName = (s.energyObjectName != null || d.energyObjectName != null) && s.energyObjectName !== d.energyObjectName;
    compSep.cardInformation = !this.compareArray(s.cardInformation, d.cardInformation);
    compSep.technicalReasons = !this.compareTechnicalReasons(s.technicalReasons, d.technicalReasons);
    compSep.misoperationReasons = !this.compareArray(s.misoperationReasons, d.misoperationReasons);
    compSep.organizationalReasons = !this.compareOrganizationalReasons(s.organizationalReasons, d.organizationalReasons);
    return compSep;
  }

  compareSes(sepId, sSep, dSep) {
    if (sSep.secondEquipmentCards.length > 0 && dSep.secondEquipmentCards.length) {
      for (let i = 0; i < sSep.secondEquipmentCards.length; i++) {
        const dSe = dSep.secondEquipmentCards.find((el) => el.secondEquipmentId === sSep.secondEquipmentCards[i].secondEquipmentId);
        if (dSe) {
          this.state.seCompare.set(`${sepId}_${dSe.secondEquipmentId}`, this.compareSe(sSep.secondEquipmentCards[i], dSe));
        }
      }
    }
  }

  /**
   * Сравнение функций
   * @param s
   * @param d
   * @returns {SepCompare}
   */
  compareSe(s, d) {
    const compSe = {};
    compSe.estimationId = (s.estimationId != null || d.estimationId != null) && s.estimationId !== d.estimationId;
    compSe.cardInformation = !this.compareArray(s.cardInformation, d.cardInformation);
    compSe.technicalReasons = !this.compareTechnicalReasons(s.technicalReasons, d.technicalReasons);
    compSe.misoperationReasons = !this.compareArray(s.misoperationReasons, d.misoperationReasons);
    compSe.organizationalReasons = !this.compareOrganizationalReasons(s.organizationalReasons, d.organizationalReasons);
    compSe.description = s.description !== d.description;
    return compSe;
  }

  getDestSepCard(sepId) {
    if (this.state.destEventCard) {
      return this.state.destEventCard.secondEquipmentPanelCards.find((el) => el.secondEquipmentPanelId === sepId);
    }
    return null;
  }

  getSourceSepCard(sepId) {
    if (this.state.sourceEventCard) {
      return this.state.sourceEventCard.secondEquipmentPanelCards.find((el) => el.secondEquipmentPanelId === sepId);
    }
    return null;
  }

  getDestSeCard(seId) {
    if (this.state.selectedDestSepCard) {
      return this.state.selectedDestSepCard.secondEquipmentCards.find((el) => el.secondEquipmentId === seId);
    }
    return null;
  }

  getSourceSeCard(sepId) {
    if (this.state.selectedSourceSepCard) {
      return this.state.selectedSourceSepCard.secondEquipmentCards.find((el) => el.secondEquipmentId === sepId);
    }
    return null;
  }

  reselectAll() {
    this.state.selectedDestSepCard = this.getDestSepCard(this.state.selectedSepId);
    this.state.selectedSourceSepCard = this.getSourceSepCard(this.state.selectedSepId);
    if (this.state.selectedDestSepCard && this.state.selectedDestSepCard.secondEquipmentCards.length > 0) {
      this.state.selectedSeId = this.state.selectedDestSepCard.secondEquipmentCards[0].secondEquipmentId;
    }  
    this.state.selectedDestSeCard = this.getDestSeCard(this.state.selectedSeId);
    this.state.selectedSourceSeCard = this.getSourceSeCard(this.state.selectedSeId);
  }

  // eslint-disable-next-line no-unused-vars,class-methods-use-this
  handleSepChange = (tab, e) => {
    this.reselectAll();
  };

// eslint-disable-next-line no-unused-vars,class-methods-use-this
  handleSeChange = (tab, e) => {
    debugger;
    this.state.selectedDestSeCard = this.getDestSeCard(this.state.selectedSeId);
    this.state.selectedSourceSeCard = this.getSourceSeCard(this.state.selectedSeId);
  };


  // eslint-disable-next-line no-unused-vars,class-methods-use-this
  handleSourceSepChange = (tab, e) => {
    this.state.selectedDestSep = tab.name;
  };

  // eslint-disable-next-line no-unused-vars,class-methods-use-this
  handleDestSepChange = (tab, e) => {
    this.state.selectedSourceSep = tab.name;
  };

  // eslint-disable-next-line no-unused-vars,class-methods-use-this
  handleSourceSeChange = (tab, e) => {
    if (this.state.selectedDestSep === this.state.selectedSourceSep) {
      this.state.selectedDestSe = tab.name;
    } else {
      this.state.selectedDestSe = -1;
    }
  };

  // eslint-disable-next-line no-unused-vars,class-methods-use-this
  handleDestSeChange = (tab, e) => {
    if (this.state.selectedDestSep === this.state.selectedSourceSep) {
      this.state.selectedSourceSe = tab.name;
    } else {
      this.state.selectedSourceSe = -1;
    }
  };

  compareTechnicalReasons(s, d) {
    if ((!s || !s.length) && (!d || !d.length)) return true;
    if (!s || !d) return false;
    if (s.length !== d.length) return false;
    return this.compareArray(s, d);
  }

  compareOrganizationalReasons(s, d) {
    if ((!s || !s.length) && (!d || !d.length)) return true;
    if (!s || !d) return false;
    if (s.length !== d.length) return false;
    return this.compareArray(s.map((el) => `${el.code}${el.category}${el.organization}`), d.map((el) => `${el.code}${el.category}${el.organization}`));
  }

  // eslint-disable-next-line class-methods-use-this
  compareArray(s, d) {
    if ((!s || !s.length) && (!d || !d.length)) return true;
    if (!s || !d) return false;
    if (s.length !== d.length) return false;

    // const sSet = new Set(s);
    const dSet = new Set(d);

    for (let i = 0; i < s.length; i++) {
      if (!dSet.has(s[i])) {
        return false;
      }
    }
    return true;
  }

  async saveAsync() {
    if (!this.state.destEventCard || !this.state.sourceEventCard) return;

    if (this.state.currentCorrelationId) {
      const updData = {
        eventCorrelationId: this.state.currentCorrelationId,
        sourceEventCardId: this.state.sourceEventCard.id,
        destEventCard: cloneDeep(this.state.destEventCard),
        statusId: this.state.statusId,
        statusEventId: this.state.currentStatusEventId
      };
      if (updData.destEventCard) {
        updData.destEventCard.eventTime = this.dateHelpers.toServerUtcDate(updData.destEventCard.eventTime);
      }
      updData.destEventCard.secondEquipmentPanelCards = updData.destEventCard.secondEquipmentPanelCards.filter((el) => !el.readOnly);
      await EventCardCorrelationRepository.updateAsync(updData);
      await this.openByIdAsync(this.state.currentCorrelationId);
    } else {
      const updData = {
        sourceEventCardId: this.state.sourceEventCard.id,
        destEventCard: cloneDeep(this.state.destEventCard),
        statusId: this.state.statusId,
        statusEventId: this.state.currentStatusEventId
      };
      if (updData.destEventCard) {
        updData.destEventCard.eventTime = this.dateHelpers.toServerUtcDate(updData.destEventCard.eventTime);
      }
      const corId = await EventCardCorrelationRepository.createAsync(updData);
      await this.openByIdAsync(corId);
    }
  }

  clear() {
    this.state.dialogOpen = false;
    this.state.currentCorrelationId = null;

    this.state.showSourceVariant = false;
    this.state.sourceVariants = [];
    this.state.selectedSourceVariantId = null;

    this.state.showDestVariant = false;
    this.state.destVariants = [];
    this.state.selectedDestVariantId = null;

    // this.state.techReasons = [];

    this.state.statusId = 0;
    this.state.statusName = '';
    this.state.statusEventId = 0;
    this.state.statusEventName = '';
    this.state.currentStatusName = '';
    this.state.currentStatusId = 0;
    this.state.currentStatusEventId = 0;


    this.state.totalMappingCount = 0;
    this.state.mappingCount = 0;

    this.state.destEventCard = null; /* Куда */
    this.state.sourceEventCard = null; /* Откуда */
    this.state.eventCompare = {};
    this.state.sepCompare = new Map();
    this.state.seCompare = new Map();
    this.state.selectedSourceSep = 0;
    this.state.selectedDestSep = 0;
    this.state.selectedSourceSe = 0;
    this.state.selectedDestSe = 0;


    this.state.selectedSepId = 0;
    this.state.selectedSeId = 0;

    this.state.labelSpan = 8;
    this.state.dataSpan = 16;
    this.state.selectedDestSepCard = null;
    this.state.selectedSourceSepCard = null;
    this.state.selectedDestSeCard = null;
    this.state.selectedSourceSeCard = null;

    this.state.hideRepeat = false;
  }

  compareSecondEquipmentPanelCards() {
    this.state.totalMappingCount = this.state?.destEventCard?.secondEquipmentPanelCards.length ?? 0;
    let mappingCount = 0;
    if (this.state.destEventCard && this.state.destEventCard.secondEquipmentPanelCards) {
      this.state.destEventCard.secondEquipmentPanelCards.forEach((el) => {
        if (el.statusId) {
          const alStats = this.getAllowedCorSepStatuses(el.currentStatusId);
          const curSt = alStats.find((el2) => el2.id === el.statusId);
          // if (!curSt && !el.readOnly) {
          //   el.statusId = null;
          // }
          if (!curSt && !el.readOnly && !this.isValidStatus(el.statusId)) {
            el.statusId = null;
          }
        }
        if (el.getStatusId() === 5 || el.getStatusId() === 6) {
          mappingCount++;
        }
      });
      debugger;
      this.setCortStatus(this.state.destEventCard.secondEquipmentPanelCards, this.state.sourceEventCard?.secondEquipmentPanelCards?.length || 0);
    }

    this.state.mappingCount = mappingCount;
  }

  checkSepState(notify) {
    if (this.state.selectedDestSepCard && this.state.selectedDestSepCard.statusId === 5) {
      if (this.state.selectedDestSepCard && this.state.selectedDestSepCard.secondEquipmentCards
        && this.state.selectedSourceSepCard
        && this.state.selectedSourceSepCard.secondEquipmentCards
        && this.state.selectedDestSepCard.secondEquipmentCards.length !== this.state.selectedSourceSepCard.secondEquipmentCards.length
      ) {
        notify.error('Разный набор ФУРЗА');
        this.state.selectedDestSepCard.statusId = null;
      }

      const dSep = this.state.sepCompare.get(this.state.selectedDestSepCard.secondEquipmentPanelId);
      if (dSep) {
        if (dSep.estimationId) {
          notify.error('Не соответствует оценка действия УРЗА');
          this.state.selectedDestSepCard.statusId = null;
        }
        if (dSep.technicalReasons) {
          notify.error('Не соответствует Технические причины УРЗА');
          this.state.selectedDestSepCard.statusId = null;
        }
        if (dSep.organizationalReasons) {
          notify.error('Не соответствует Организационные причины УРЗА');
          this.state.selectedDestSepCard.statusId = null;
        }
      }
      if (this.state.selectedDestSepCard.secondEquipmentCards && this.state.selectedDestSepCard.secondEquipmentCards.length) {
        this.state.selectedDestSepCard.secondEquipmentCards.forEach((se) => {
          const dSe = this.state.seCompare.get(`${se.secondEquipmentPanelId}_${se.secondEquipmentId}`);
          if (dSe) {
            if (dSe.estimationId) {
              notify.error('Не соответствует оценка действия ФУРЗА');
              this.state.selectedDestSepCard.statusId = null;
            }
            if (dSe.technicalReasons) {
              notify.error('Не соответствует Технические причины ФУРЗА');
              this.state.selectedDestSepCard.statusId = null;
            }
            if (dSe.organizationalReasons) {
              notify.error('Не соответствует Организационные причины ФУРЗА');
              this.state.selectedDestSepCard.statusId = null;
            }
          }
        });
      }
    }
  }

  handleSepStatusChange = () => {
    this.compareSecondEquipmentPanelCards();
  };

  hideRepeat = (v) => {
    if (!this.state.sourceEventCard || !this.state.destEventCard) {
      return;
    }
    if (v) {
      this.internalHideRepeat();
    }
  };

  // eslint-disable-next-line class-methods-use-this
  internalHideRepeat() {
    if (this.state.destEventCard.secondEquipmentPanelCards) {
      this.state.destEventCard.secondEquipmentPanelCards.forEach((sep) => {
        set(sep, 'isEqual', true);
        const sourceSep = this.getSourceSep(sep.secondEquipmentPanelId);
        if (sourceSep) {
          set(sourceSep, 'isEqual', true);
        }

        if (sep.secondEquipmentCards) {
          sep.secondEquipmentCards.forEach((se) => {
            set(se, 'isEqual', true);
            const sourceSe = this.getSourceSe(sourceSep, se.secondEquipmentId);
            if (sourceSe) {
              set(sourceSe, 'isEqual', true);
            }
            const dse = this.state.seCompare.get(`${sep.secondEquipmentPanelId}_${se.secondEquipmentId}`);
            if (dse) {
              if (!this.checkEqual(dse)) {
                se.isEqual = false;
                if (sourceSe) {
                  sourceSe.isEqual = false;
                }
                sep.isEqual = false;
                if (sourceSep) {
                  sourceSep.isEqual = false;
                }
              }
            } else {
              se.isEqual = false;
              if (sourceSe) {
                sourceSe.isEqual = false;
              }
            }

            if (se.isEqual && this.state.hideRepeat && this.state.selectedSeId === se.secondEquipmentId) {
              this.state.selectedSeId = null;
            }
          }, this);
        }

        if (sep.isEqual) {
          const dSep = this.state.sepCompare.get(sep.secondEquipmentPanelId);
          if (dSep) {
            if (!this.checkEqual(dSep)) {
              sep.isEqual = false;
              if (sourceSep) {
                sourceSep.isEqual = false;
              }
            }
          } else {
            sep.isEqual = false;
            if (sourceSep) {
              sourceSep.isEqual = false;
            }
          }
        }

        if (sep.isEqual && this.state.hideRepeat && this.state.selectedSepId === sep.secondEquipmentPanelId) {
          this.state.selectedSepId = null;
        }
      }, this);
    }
  }

  getSourceSep(sepId) {
    if (!this.state.sourceEventCard) return null;
    return this.state.sourceEventCard.secondEquipmentPanelCards.find((el) => el.secondEquipmentPanelId === sepId);
  }

  // eslint-disable-next-line class-methods-use-this
  getSourceSe(sep, seId) {
    if (!sep) return null;
    return sep.secondEquipmentCards.find((el) => el.secondEquipmentId === seId);
  }

  // eslint-disable-next-line class-methods-use-this
  checkEqual(d) {
    // eslint-disable-next-line no-restricted-syntax
    for (const p in d) {
      if (d[p]) {
        return false;
      }
    }
    return true;
  }


  // eslint-disable-next-line class-methods-use-this
  prepareValidate(errors) {
    const valData = this.getDataFromValidateError(errors);
    if (!valData) return false;
    this.selected(this.state.destEventCard, valData.sepInd, valData.seInd);
    return true;
  }

  selected(dEvent, sepInd = 0, seInd = 0) {
    if (dEvent && dEvent.secondEquipmentPanelCards) {
      const sep = dEvent.secondEquipmentPanelCards[sepInd];
      if (sep) {
        this.state.selectedSepId = sep.secondEquipmentPanelId;

        // this.state.selectedDestSepCard = sep;
        // this.state.selectedSourceSepCard = sep;

        if (seInd || seInd === 0) {
          const se = sep.secondEquipmentCards[seInd];
          if (se) {
            this.state.selectedSeId = se.secondEquipmentId;
            //    this.state.selectedDestSeCard = se;
            //   this.state.selectedSourceSeCard = se;
          }
        }
        this.reselectAll();
      }
    }
  }

  // sepRegExp = RegExp('^secondEquipmentPanelCards\\.(?<sepInd>[\\d]+)');

  seRegExp = RegExp('^secondEquipmentPanelCards\\.(?<sepInd>[\\d]+)(\\.secondEquipmentCards\\.(?<seInd>[\\d]+))?');

  getDataFromValidateError(errors) {
    if (!errors) return false;
    // eslint-disable-next-line no-restricted-syntax,no-unused-vars
    for (const [key, value] of Object.entries(errors)) {
      const res = this.seRegExp.exec(key);
      if (res.groups) {
        if (res.groups.sepInd && res.groups.seInd && !Number.isNaN(+res.groups.sepInd) && !Number.isNaN(+res.groups.seInd)) {
          return {
            sepInd: +res.groups.sepInd,
            seInd: +res.groups.seInd,
          };
        }
        if (res.groups.sepInd && !res.groups.seInd && !Number.isNaN(+res.groups.sepInd)) {
          return {
            sepInd: +res.groups.sepInd,
          };
        }
      }
    }
    return null;
  }

  /**
   * Копировать событие целиком
   * @returns {any}
   */
  // eslint-disable-next-line consistent-return
  copyEvent() {
    if (!this.state.destEventCard && this.state.sourceEventCard) {
      const addEv = JSON.parse(JSON.stringify(this.state.sourceEventCard));
      /* Сбросили при копировании ИД карточки и все */
      addEv.id = this.GUID_EMPTY;

      this.copySecondEquipmentPanelCards(addEv);
      // addEv.secondEquipmentPanelCards.forEach((el) => {
      //   el.sourceSepCardId = el.id;
      //   el.id = GUID_EMPTY;
      //   const initSt = this.getInitCorSepStatus();
      //   el.statusName = initSt.name;
      //   el.statusId = initSt.id;
      //   el.currentStatusId = initSt.id;
      //   this.copyOrganizationalReasons(el);
      //   if (el.secondEquipmentCards) {
      //     el.secondEquipmentCards.forEach((el2) => {
      //       el2.id = GUID_EMPTY;
      //       this.copyOrganizationalReasons(el2);
      //     });
      //   }
      // });
      return addEv;
    }
  }

  copySep() {
    if (!this.state.selectedDestSepCard && this.state.selectedSourceSepCard) {
      const addsep = JSON.parse(JSON.stringify(this.state.selectedSourceSepCard));
      this.copySecondEquipmentPanelCard(addsep);
      this.state.destEventCard.secondEquipmentPanelCards.push(addsep);
    }
  }

  copySe() {
    if (!this.state.selectedDestSeCard && this.state.selectedSourceSeCard) {
      const addSe = JSON.parse(JSON.stringify(this.state.selectedSourceSeCard));
      this.copySecondEquipmentCard(addSe);
      this.state.selectedDestSepCard.secondEquipmentCards.push(addSe);
    }
  }

  copySecondEquipmentPanelCards(el) {
    if (el && el.secondEquipmentPanelCards) {
      el.secondEquipmentPanelCards.forEach((el2) => {
        this.copySecondEquipmentPanelCard(el2);
      });
    }
  }

  copySecondEquipmentPanelCard(el2) {
    if (el2) {
      el2.sourceSepCardId = el2.id;
      el2.id = this.GUID_EMPTY;
      const initSt = this.getInitCorSepStatus();
      el2.statusName = initSt.name;
      el2.statusId = initSt.id;
      el2.currentStatusId = initSt.id;

      el2.getStatusId = function () {
        return this.statusId ? this.statusId : this.currentStatusId;
      };

      this.copyOrganizationalReasons(el2);
      if (el2.secondEquipmentCards) {
        el2.secondEquipmentCards.forEach((el3) => {
          el3.id = this.GUID_EMPTY;
          this.copyOrganizationalReasons(el3);
        });
      }
    }
  }

  copySecondEquipmentCards(el2) {
    if (el2.secondEquipmentCards) {
      el2.secondEquipmentCards.forEach((el3) => {
        this.copySecondEquipmentCard(el3);
      });
    }
  }

  copySecondEquipmentCard(el3) {
    if (el3) {
      el3.id = this.GUID_EMPTY;
      this.copyOrganizationalReasons(el3);
    }
  }

  // eslint-disable-next-line class-methods-use-this
  copyOrganizationalReasons(el) {
    if (el.organizationalReasons) {
      el.organizationalReasons.forEach((el3) => {
        el3.id = this.GUID_EMPTY;
      });
    }
  }

  // eslint-disable-next-line class-methods-use-this
  handleSetState(isExternalUser) {
    this.internalHideRepeat();
    if (!(this.state.currentStatusId === 1 && isExternalUser)
      && !(this.state.currentStatusId === 2 && !isExternalUser)) {
      if (this.state.destEventCard.secondEquipmentPanelCards) {
        this.state.destEventCard.secondEquipmentPanelCards.filter((x) => !x.readOnly).forEach((sep) => {
          if (this.state.sourceEventCard.secondEquipmentPanelCards.find((x) => x.secondEquipmentPanelId === sep.secondEquipmentPanelId)) {
            sep.statusId = 5;
          }
        });
      }
    }
  }

  estimationValidation = (value, obj) => {
    const fEstimations = this.state.estimations.filter((x) => x.code === 'ПУ1'
      || x.code === 'ПУ2'
      || x.code === 'П'
      || x.code === 'ПУ'
      || x.code === 'ПН'
      || x.code === 'П сигнал');

    if (value === null) {
      return {
        $valid: false,
        $message: 'Поле обязательно для заполнения'
      };
    } else if (fEstimations.some((x) => x.id === value)
      && (obj.technicalReasons.length > 0 || obj.organizationalReasons.length > 0 || obj.misoperationReasons.length > 0)) {
      return {
        $valid: false,
        $message: 'Нельзя выбрать данную оценку, так как заполнены технические, организационные или детализированные причины неправильной работы'
      };
    } else {
      return true;
    }
  };

  orgReasonsValidation = (reasons) => {
    const duplicate = this.searchDuplicateOrgReasons(reasons);
    const missed = this.searchMissedFieldsOrgReasons(reasons);

    if (duplicate) {
      return {
        $valid: false,
        $message: 'Указаны две или более одинаковые организационные причины'
      };
    }
    if (missed) {
      return {
        $valid: false,
        $message: 'Все поля обязательны для заполнения'
      };
    }
// this.$refs.cardForm.rules.organizationalReasons.required = false;
    return true;
  };

  orgReasonsRequiredValidation = (reasons, obj) => {
    const req = !!(this.state.filteredEstimations.find((el) => el.id === obj.estimationId));
    return {
      $valid: !req || helpers.req(reasons),
      required: req,
      $message: 'Поле обязательно для заполнения'
    };
    // $message: 'Нельзя выбрать данную оценку, так как заполнены технические, организационные или детализированные причины неправильной работы'
  };

  technicalReasonValidation = (value, obj) => {
    // if (this.filteredEstimations.find((el) => el.id === obj.estimationId)) {
    // eslint-disable-next-line implicit-arrow-linebreak
    const req = !!(this.state.filteredEstimations.find((el) => el.id === obj.estimationId));
    return {
      $valid: !req || helpers.req(value),
      required: req,
      $message: 'Поле обязательно для заполнения'
      // $message: 'Нельзя выбрать данную оценку, так как заполнены технические, организационные или детализированные причины неправильной работы'
    };
  };

// this.$refs.cardForm.rules.organizationalReasons.required = false;
  //   return true;
  // }


  // eslint-disable-next-line class-methods-use-this
  searchDuplicateOrgReasons(array) {
    // eslint-disable-next-line no-shadow
    return array.map((value) => value.code + value.category + value.organization)
      .some((value, index, a) => a.indexOf(value) !== a.lastIndexOf(value));
  }

  searchMissedFieldsOrgReasons(array) {
    let result = false;
    // eslint-disable-next-line func-names
    // eslint-disable-next-line consistent-return
    array.forEach((x) => {
      if (this.state.allowStaffCategories.find((el) => el.id === x.category)) {
        if (x.code === null || x.code === '' || x.category === null || x.category === '') {
          result = true;
        }
      } else if (x.organization === null || x.organization === '' || x.code === null || x.code === '' || x.category === null || x.category === '') {
        result = true;
      }
    });
    return result;
  }

  validateEvent(dEvent) {
    const rootErr = {
      valid: true,
      errors: []
    };
    if (!dEvent) return rootErr;
    // debugger;
    // if (this.state.eventCompare.eventTypeId) {
    //   rootErr.errors.push('Тип события должен совпадать');
    //   // rootErr.valid = false;
    //   // return rootErr;
    // }

    // if (this.state.eventCompare.shortCircuitFormId) {
    //   rootErr.errors.push('Вид КЗ должен совпадать');
    //   // rootErr.valid = false;
    //   // return rootErr;
    // }

    // if (this.state.eventCompare.shortCircuitPlaceId) {
    //   rootErr.errors.push('Место КЗ должно совпадать');
    //   // rootErr.valid = false;
    //   // return rootErr;
    // }

    // if (this.state.eventCompare.faultedPhases) {
    //   rootErr.errors.push('Поврежденные фазы должны совпадать');
    //   // rootErr.valid = false;
    //   // return rootErr;
    // }
    if (this.state.currentStatusEventId === 5) {
      if (this.state.eventCompare.eventTypeId) {
        rootErr.errors.push('Тип события должен совпадать');
        rootErr.valid = false;
        return rootErr;
      }

      if (this.state.eventCompare.shortCircuitFormId) {
        rootErr.errors.push('Вид КЗ должен совпадать');
        rootErr.valid = false;
        return rootErr;
      }

      if (this.state.eventCompare.shortCircuitPlaceId) {
        rootErr.errors.push('Место КЗ должно совпадать');
        rootErr.valid = false;
        return rootErr;
      }

      if (this.state.eventCompare.faultedPhases) {
        rootErr.errors.push('Поврежденные фазы должны совпадать');
        rootErr.valid = false;
        return rootErr;
      }
    } 

    if (this.state.currentStatusEventId === null) {
      rootErr.errors.push('Не определен статус сопоставления атрибутов события');
      rootErr.valid = false;
      return rootErr;
    }

    rootErr.secondEquipmentPanelCards = [];
    // dEvent.secondEquipmentPanelCards?.filter((el) => !el.readOnly).forEach((el, i) => {
      const dEvents = dEvent.secondEquipmentPanelCards.filter((el) => !el.readOnly);
    for (let i = 0; i < dEvents.length; i++) {
        const el = dEvents[i];
      if (!this.state.sourceEventCard.secondEquipmentPanelCards.find((x) => x.secondEquipmentPanelId === el.secondEquipmentPanelId)) {
        i++;
        // eslint-disable-next-line no-continue
        continue;
      }
      rootErr.secondEquipmentPanelCards[i] = this.validateSep(el, rootErr);
      if (!el.statusId) {
        rootErr.errors.push('Не определен статус сопоставления УРЗА');
        rootErr.valid = false;
      }
      if ((el.statusId === 1 && dEvent.secondEquipmentPanelCards.some((item) => item.statusId === 2 || item.statusId === 4)) 
        || (el.statusId === 3 && dEvent.secondEquipmentPanelCards.some((item) => item.statusId === 2 || item.statusId === 4))) {
        rootErr.errors.push('Недопустимое сочетание статусов сопоставления УРЗА');
        rootErr.valid = false;
      }
      // eslint-disable-next-line operator-assignment
      // rootErr.valid = rootErr.secondEquipmentPanelCards[i].valid && rootErr.valid;
    }
    if (rootErr.secondEquipmentPanelCards.length === 0) {
      delete rootErr.secondEquipmentPanelCards;
    }
    return rootErr;
  }


  // eslint-disable-next-line class-methods-use-this
  validateSep(obj, rootErr) {
    const err = {
      valid: true
    };
    if (!obj) return err;
    err.estimationId = [];
    err.organizationalReasons = [];
    err.technicalReasons = [];
    err.secondEquipmentCards = [];
    this.validateEstimationId(obj, err, rootErr, obj.secondEquipmentPanelName);
    this.validateOrganizationalReasons(obj, err, rootErr, obj.secondEquipmentPanelName);
    this.validateTechnicalReasons(obj, err, rootErr, obj.secondEquipmentPanelName);
    // eslint-disable-next-line no-unused-expressions
    obj.secondEquipmentCards?.forEach((el, i) => {
      err.secondEquipmentCards[i] = this.validateSe(el, rootErr);
      // err.valid = err.secondEquipmentCards[i].valid && err.valid;
    });

    if (err.estimationId.length === 0) {
      delete err.estimationId;
    }
    if (err.organizationalReasons.length === 0) {
      delete err.organizationalReasons;
    }
    if (err.technicalReasons.length === 0) {
      delete err.technicalReasons;
    }
    if (err.secondEquipmentCards.length === 0) {
      delete err.secondEquipmentCards;
    }

    return err;
  }

  // eslint-disable-next-line consistent-return
  validateSe(obj, rootErr) {
    const err = {
      valid: true
    };
    if (!obj) return err;
    err.estimationId = [];
    err.organizationalReasons = [];
    err.technicalReasons = [];
    this.validateEstimationId(obj, err, rootErr, obj.secondEquipmentName);
    this.validateOrganizationalReasons(obj, err, rootErr, obj.secondEquipmentName);
    this.validateTechnicalReasons(obj, err, rootErr, obj.secondEquipmentName);
    if (err.estimationId.length === 0) {
      delete err.estimationId;
    }
    if (err.organizationalReasons.length === 0) {
      delete err.organizationalReasons;
    }
    if (err.technicalReasons.length === 0) {
      delete err.technicalReasons;
    }
    return err;
  }

  validateEstimationId(obj, err, rootErr, name) {
    if (!obj.estimationId) {
      err.estimationId.push('Поле обязательно для заполнения');
    } else {
      const esErr = this.estimationValidation(obj.estimationId, obj);
      if (esErr !== true) {
        err.estimationId.push(esErr.$message);
        err.valid = false;
        rootErr.valid = false;
        rootErr.errors.push(`${name} - Оценка действия: ${esErr.$message}`);
        //  err.set('Оценка действия', esErr.$message);
      }
    }
  }

  validateOrganizationalReasons(obj, err, rootErr, name) {
    const esErr = this.orgReasonsValidation(obj.organizationalReasons, obj);
    if (esErr !== true) {
      err.organizationalReasons.push(esErr.$message);
      err.valid = false;
      rootErr.valid = false;
      rootErr.errors.push(`${name} - Организационные причины: ${esErr.$message}`);
    }
    const esErr2 = this.orgReasonsRequiredValidation(obj.organizationalReasons, obj);
    if (!esErr2.$valid && esErr2.required) {
      err.organizationalReasons.push(esErr2.$message);
      err.valid = false;
      rootErr.valid = false;
      rootErr.errors.push(`${name} - Организационные причины: ${esErr2.$message}`);
    }
  }

  validateTechnicalReasons(obj, err, rootErr, name) {
    const esErr = this.technicalReasonValidation(obj.technicalReasons, obj);
    if (!esErr.$valid && esErr.required) {
      err.technicalReasons.push(esErr.$message);
      err.valid = false;
      rootErr.valid = false;
      rootErr.errors.push(`${name} - Технические причины: ${esErr.$message}`);
    }
  }

  getStatus(statusId) {
    return this.isExternal 
    ? this.extSepStatuses.find((el) => el.id === statusId) 
    : this.intSepStatuses.find((el) => el.id === statusId);
  }

  // Сопоставление корректного статуса события
  getCorStatus(sep, destSepCount) {
    this.state.statusEventName =  this.getStatus(this.state.currentStatusEventId).name;

    if (sep && sep.length) {
      const outSep = sep.filter((el) => el.getStatusId() !== 6 && el.getStatusId() !== 5 && el.readOnly === false);

      if (sep.filter((el) => el.getStatusId() === 0).length > 0) {
        if (this.state.currentStatusId === 0) {
          return this.isExternal ? 2 : 1;
        }
        return this.state.currentStatusId;
      }  

      const noHisDS = sep.filter((el) => el.readOnly === true);
      if (noHisDS.length > 0) {
        const noHisDSMinStatus = noHisDS.reduce((acc, curr) => (acc.statusId < curr.statusId ? acc : curr));
        if (noHisDSMinStatus && (noHisDSMinStatus.statusId !== 5 && noHisDSMinStatus.statusId !== 6)) {
          if (noHisDSMinStatus.statusId === 1 || noHisDSMinStatus.statusId === 3) {
            return this.setEventStatus(1);
          } 
          if (noHisDSMinStatus.statusId === 2 || noHisDSMinStatus.statusId === 4) {
            return this.setEventStatus(2);
          }          
        }
      }   
      
      // Проверка статусов 1 и 3
      let fsep = sep.filter((el) => (el.getStatusId() === 1 || el.getStatusId() === 3) && el.readOnly === false);
      if (fsep.length !== 0 && fsep.length <= outSep.length) {
        return this.setEventStatus(1);
      }

      // Проверка статусов 2 и 4
      fsep = sep.filter((el) => (el.getStatusId() === 2 || el.getStatusId() === 4)  && el.readOnly === false);
      if (fsep.length !== 0 && fsep.length <= outSep.length) {
        return this.setEventStatus(2);
      }
      // Проверка статуса 6
      fsep = sep.find((el) => el.getStatusId() === 6);
      if ((fsep || sep.length !== destSepCount)) {
        return this.setEventStatus(3);
      }

      // Проверка статуса 5
      fsep = sep.filter((el) => el.getStatusId() === 5);
      if (fsep.length === sep.length) {
        return this.setEventStatus(4);
      }
    }
    return 0;
  }

  setEventStatus(status) {
    const statusId = this.state.statusEventId;
    // Фильтрация статусов события по текущему статусу
    let eventStatuses = this.isExternal 
      ? this.extSepStatuses.filter((el) => el.prev.has(statusId)) 
      : this.intSepStatuses.filter((el) => el.prev.has(statusId));
    if (eventStatuses.length === 0) return status;
    const conditions = [
      this.state.eventCompare.eventTypeId,
      this.state.eventCompare.shortCircuitFormId,
      this.state.eventCompare.shortCircuitPlaceId,
      this.state.eventCompare.faultedPhases,
    ];
    if (status === 1) {
      eventStatuses = eventStatuses.filter((el) => (conditions.some((condition) => condition) ? el.id === 1 || el.id === 3  : el.id === 5));
    }
    if (status === 2) {
      eventStatuses = eventStatuses.filter((el) => (conditions.some((condition) => condition) ? el.id === 2 || el.id === 4  : el.id === 5));
    } 
    if (status === 3 || status === 4) {
      if (this.state.currentStatusId !== 3 && this.state.currentStatusId !== 4) {
        if (conditions.some((condition) => condition)) {
          if (status === 3) {
            eventStatuses = eventStatuses.filter((el) => el.id === 6);
          } 
          if (status === 4) {
            eventStatuses = eventStatuses.filter((el) => (conditions.some((condition) => condition) 
            ? (el.id !== statusId && el.id !== 5) || el.id === 6  : el.id === 5));
          }
        } else {
          eventStatuses = this.intSepStatuses.filter((el) => el.id === 5);
        } 
      }           
    }
  // определение статуса сопоставления 
    let fev;
    if (conditions.some((condition) => condition)) {
      if (eventStatuses.length > 0) {
        // eslint-disable-next-line prefer-destructuring
        fev = eventStatuses.length > 1 ? eventStatuses[1] : eventStatuses[0];
      } else {
        fev = this.intSepStatuses.find((el) => el.id === 6);
      }
    } else {
      fev = this.intSepStatuses.find((el) => el.id === 5);
    }
    this.state.currentStatusEventId = fev.id;
    this.state.statusEventName = fev.name;
    if ((fev.id === 1 || fev.id === 3) && (status === 1 || status === 3 || status === 4)) {
      return 1;
    } 
    if ((fev.id === 2 || fev.id === 4) && (status === 2 || status === 3 || status === 4)) {
      return 2;            
    }  
    if ((fev.id === 6) && (status === 3 || status === 4)) {
      return 3;
    }  
    if (fev.id === 5 && status === 4) {
      return 4;
    }  
    return status;
  }

  setCortStatus(sep, destSepCount) {
    const corSeps = sep.filter((el) => this.state.sourceEventCard.secondEquipmentPanelCards.find((x) => x.secondEquipmentPanelId === el.secondEquipmentPanelId));
   
    const corStateId = this.getCorStatus(corSeps, destSepCount);
    const st = this.corStatuses.find((el) => el.id === corStateId);


    this.state.statusId = st.id;
    this.state.statusName = st.name;
  }
}

export default EventMappingModalStore;



